import { defineStore } from 'pinia'
import { useDomainStore } from '~/stores/domain'
import type { RangeHighLow } from '~/utils/types/global'
import {
    CreditScoreTier,
    DownPaymentPreference,
} from '~/utils/types/visitor/enums'
import type { PaymentData } from '~/utils/types/paymentCalculator'
import { removeEmptyValuesFromObject } from '~/utils/functions'

export const usePaymentCalculatorStore = defineStore('paymentCalculator', {
    state: (): State => ({
        paymentData: {},
        defaultCreditScoreRanges: [
            {
                title: 'Rebuilding',
                tier: CreditScoreTier.C,
                range: {
                    low: null,
                    high: 639,
                },
                apr: 0.1299,
            },
            {
                title: 'Good',
                tier: CreditScoreTier.B,
                range: {
                    low: 640,
                    high: 699,
                },
                apr: 0.0999,
            },
            {
                title: 'Very Good',
                tier: CreditScoreTier.A,
                range: {
                    low: 700,
                    high: 750,
                },
                apr: 0.0899,
            },
            {
                title: 'Excellent',
                tier: CreditScoreTier.AA,
                range: {
                    low: 751,
                    high: null,
                },
                apr: 0.0799,
            },
        ],
        defaultTermMonths: [36, 48, 60, 72, 84],
        defaultTermLength: 72,
        defaultTier: CreditScoreTier.AA,
        defaultDownPaymentPref: DownPaymentPreference.CASH,
        defaultDownPercentage: 20,
    }),
    persist: {
        storage: piniaPluginPersistedstate.localStorage(),
        pick: ['paymentData'],
    },
    getters: {
        creditScoreRanges(state): CreditScoreRange[] {
            return (
                useDomainStore().payments.defaults.creditRanges ??
                state.defaultCreditScoreRanges
            )
        },
        termMonths(state): number[] {
            return (
                useDomainStore().payments.defaults.termMonths ??
                state.defaultTermMonths
            )
        },
        currentDownPaymentPref(state) {
            return state.paymentData.down_pref ?? state.defaultDownPaymentPref
        },
        currentTermLength(state) {
            return (
                state.paymentData.term_length ??
                useDomainStore().payments.defaults.termLength ??
                state.defaultTermLength
            )
        },
        currentTier(state) {
            return (
                state.paymentData.tier ??
                useDomainStore().payments.defaults.tier ??
                state.defaultTier
            )
        },
        currentRange(): CreditScoreRange {
            return (
                this.creditScoreRanges.find(
                    (range: CreditScoreRange) =>
                        range.tier === this.currentTier,
                ) ?? ({} as CreditScoreRange)
            )
        },
        currentRangeCreditScoreFormatted(): string {
            const range = this.currentRange.range

            if (range.low === null) {
                return `${range.high}-`
            } else if (range.high === null) {
                return `${range.low}+`
            }

            return `${range.low}-${range.high}`
        },
        currentAPR(): number {
            return this.currentRange.apr
        },
    },
    actions: {
        updatePaymentData(data: PaymentData) {
            this.paymentData = {
                ...(this.paymentData ?? {}),
                ...removeEmptyValuesFromObject(data),
            }
        },
        getRangeFromCreditScore(creditScore: number): CreditScoreRange | null {
            return (
                this.creditScoreRanges.find((scoreRange: CreditScoreRange) => {
                    let low: number | null = scoreRange.range.low
                    let high: number | null = scoreRange.range.high

                    if (low === null && creditScore <= (high ?? 0)) {
                        return scoreRange
                    } else if (high === null && creditScore >= (low ?? 0)) {
                        return scoreRange
                    } else {
                        low = low ?? 0
                        high = high ?? 0

                        if (creditScore >= low && creditScore <= high) {
                            return scoreRange
                        }
                    }

                    return null
                }) ?? null
            )
        },
        getEstimatedMonthlyPayment(
            price: number,
            downPayment: number,
        ): number | null {
            if (price === 0 || price <= downPayment) {
                return null
            }

            const taxRate = 0 // TODO: Add taxes
            const interestRatePerMonth = this.currentAPR / 12
            const loanAmount = price - downPayment
            const taxAmount = loanAmount * taxRate
            const loanAmountWithTax = loanAmount + taxAmount

            return (
                (loanAmountWithTax *
                    interestRatePerMonth *
                    Math.pow(
                        1 + interestRatePerMonth,
                        this.currentTermLength,
                    )) /
                (Math.pow(1 + interestRatePerMonth, this.currentTermLength) - 1)
            )
        },
    },
})

interface State {
    paymentData: PaymentData
    defaultCreditScoreRanges: CreditScoreRange[]
    defaultTermMonths: number[]
    defaultTermLength: number
    defaultTier: CreditScoreTier
    defaultDownPaymentPref: DownPaymentPreference
    defaultDownPercentage: number
}

export interface CreditScoreRange {
    title: string
    tier: CreditScoreTier
    range: RangeHighLow
    apr: number
}
