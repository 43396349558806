export enum CreditScoreTier {
    AA = 'AA',
    A = 'A',
    B = 'B',
    C = 'C',
}

export enum DownPaymentPreference {
    CASH = 'cash',
    PERCENTAGE = 'percentage',
}
